import { QtmTypography } from '@qtm/react';
import clsx from 'clsx';
import { useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import isNumber from 'lodash/isNumber';

const RevisitTimeSummary = () => {
  const revisitTime = useAppSelector(({ selection }) => selection.revisitTime);
  const { isSm } = useTailwindBreakpoints();

  if (!isNumber(revisitTime)) {
    return null;
  }

  const hours = revisitTime === 0 ? '30 minutes' : `${revisitTime} hours`;

  return (
    <div>
      <QtmTypography
        classes={clsx('text-white-100  font-helvetica mb-xs', {
          'text-scale-6': isSm,
          'text-scale-5': !isSm,
        })}
      >
        Revisit Time {hours}
      </QtmTypography>
    </div>
  );
};

export default RevisitTimeSummary;
