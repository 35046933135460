import { QtmTypography } from '@qtm/react';
import clsx from 'clsx';
import { useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import isNumber from 'lodash/isNumber';

const RatioSummary = () => {
  const ratio = useAppSelector(({ selection }) => selection.ratio);
  const { isSm } = useTailwindBreakpoints();

  if (!isNumber(ratio)) {
    return null;
  }

  return (
    <div>
      <QtmTypography
        classes={clsx('text-white-100  font-helvetica', {
          'text-scale-6': isSm,
          'text-scale-5': !isSm,
        })}
      >
        {ratio}% Radar - {100 - ratio}% Optical
      </QtmTypography>
    </div>
  );
};

export default RatioSummary;
